<template>
  <b-card>
    <b-form @submit.prevent="saveNotification">
      <b-container>
        <b-row class="justify-content-md-center">
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="flat-warning"
              class="btn-icon"
              to="/notifications"
              v-b-tooltip.hover
              title="Go Back"
            >
              <feather-icon icon="ArrowLeftCircleIcon" size="20" />
            </b-button>
          </b-col>
          <b-col cols="12" md="10">
            <h1>Notifications Creation</h1>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Account" label-for="d-name">
              <div>
                <!-- <b-form-select
                  @change="onChange($event)"
                  v-model="accSelected"
                  :options="accounts"
                /> -->
                <v-select
                  v-model="accSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="accounts"
                  @input="getAllApps"
                />
                <!-- accSelected: <strong>{{ accSelected }}</strong> -->
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="App" label-for="d-name">
              <div>
                <!-- <b-form-select v-model="appSelected" :options="apps" /> -->
                <v-select
                  v-model="appSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="apps"
                />
                <!-- appSelected: <strong>{{ appSelected }}</strong> -->
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Notification title" label-for="d-name">
              <b-form-input
                id="d-name"
                v-model="notification_title"
                placeholder="Notification title"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Notification content" label-for="d-name">
              <b-form-textarea
                id="d-name"
                v-model="notification_content"
                placeholder="Notification content"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="App id" label-for="d-name">
              <b-form-input
                id="d-name"
                v-model="appId"
                placeholder="App id"
                autofocus
              />
            </b-form-group>
          </b-col>
        </b-row>
      
         <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Account id" label-for="d-name">
              <b-form-input
                id="d-name"
                v-model="account_id"
                placeholder="Account id"
                autofocus
              />
            </b-form-group>
          </b-col>
        </b-row> -->

        <!--select-->

        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Type" label-for="d-name">
              <div>
                <v-select
                  v-model="typeSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="types"
                  @input="typeselect"
                />
                <!-- typeSelected: <strong>{{ typeSelected }}</strong> -->
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="linkType" class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Notification link" label-for="d-name">
              <b-form-input
                id="d-name"
                v-model="notification_link"
                type="url"
                placeholder="https://www.example.com"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Day" label-for="d-name">
              <div>
                <!-- <b-form-select v-model="daySelected" :options="days" /> -->
                <v-select
                  v-model="daySelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="days"
                />
                <!-- daySelected: <strong>{{ daySelected }}</strong> -->
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center">
          <!-- submit and reset -->
          <b-col>
            <!-- <b-col offset-md="4"> -->
            <b-button
              type="submit"
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="outline-success"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button
              type="reset"
              ref="resetButton"
              variant="outline-secondary"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import { BFormSelect } from "bootstrap-vue";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
  BFormTextarea,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    // BCardCode,
    BFormSelect,
    BRow,
    BCol,
    vSelect,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    VBTooltip,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      linkType: false,
      // title,
      // appId,
      accSelected: null,
      appSelected: null,
      daySelected: null,
      typeSelected: null,
      // dayNames: [
      //   0,
      //   "Monday",
      //   "Tuesday",
      //   "Wednesday",
      //   "Thursday",
      //   "Friday",
      //   "Saturday",
      //   "Sunday",
      // ],

      account_name: null,
      notification_title: null,
      notification_content: null,
      notification_link: null,

      account_link: null,
      // script_link: null,
      data: {},
      // validation rules
      required,
      regex,
      url,

      apps: [],
      // types:['simple','link'],
      types: [],
      accounts: [],
      accounts_name_id: [],
      // days: [1,2,3,4,5,6,7],
      days: [
        { text: 1, value: 1 },
        { text: 2, value: 2 },
        { text: 3, value: 3 },
        { text: 4, value: 4 },
        { text: 5, value: 5 },
        { text: 6, value: 6 },
        { text: 7, value: 7 },
      ],
    };
  },

  methods: {
    typeselect() {
      if (this.typeSelected.text == "link") {
        this.linkType = true;
      } else {
        this.linkType = false;
      }
    },
    onChange() {
      this.getAllApps();
    },
    getAllTypes() {
      axios.get("/get-types").then(({ data }) => {
        data[0].forEach((el) => {
          this.types.push({ text: el.notif_type, value: el.id });
        });
      });
    },
    getAllAccounts() {
      this.$store
        .dispatch("appsManager/getAllAccounts")
        .then(({ data }) => {
          if (data.accounts.length > 0) {
            data.accounts.forEach((el) => {
              this.accounts.push({ text: el.account_name, value: el.id });
            });
            this.totalRows = this.accounts.length;
          }
        })
        .catch(console.error);
      // this.getAllApps();
    },
    getAllApps() {
      axios
        // .post(`/get-apps-by-account/${this.accSelected.value}`)
        .post(`/get-apps-by-account`, {
          accountId:this.accSelected.value,
          user_token: JSON.parse(localStorage.getItem("accessToken")),
        })
        .then(({ data }) => {
          // this.accounts = res.data[0];
          this.totalRows = this.apps.length;

          if (data.apps.length > 0) {
            data.apps.forEach((el) => {
              this.apps.push({ text: el.title, value: el.id });
            });
          }
        })
        .catch(console.error);
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    ImageInputChanged(e) {
      this.image = e.target.files[0];
    },
    async saveNotification() {
      const notifData = {
        notification_title: this.notification_title,
        notification_content: this.notification_content,
        notification_link: this.notification_link,
        appId: this.appSelected.value,
        account_id: this.accSelected.value,
        notif_day: this.daySelected.value,
        // notif_day_name: this.dayNames[this.daySelected.value],
        type: this.typeSelected.value,
      };
      await axios
        .post("/addNotification", notifData)
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            this.$refs.resetButton.click();
            router.push({ name: "notifications" });
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
  },
  created() {
    this.getAllAccounts();
    this.getAllTypes();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
